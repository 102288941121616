import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResolveStart, RouteConfigLoadStart, Router, Event } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { SignalRService } from '~app/shared/services/signalr.service';
import { AppComponentStore } from '~app/shared/store/app-component.store';
import { UserInfoService } from '~app/shared/services/user-info.service';
import { UserType } from '~app/shared/enums/user-type.enum';
import { FileService } from '~app/shared/services/file.service';
import { GoogleAnalyticsService } from '~app/shared/services/google-analytics.service';

@Component({
  selector: 'tmc-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  public title = 'Tecan Mission Control';
  public isRouteLoading = true;
  public isLoading$ = this.auth.isLoading$.pipe(map(Boolean));
  public isAppComponentLoading$ = this.appComponentStore.isLoading$;

  constructor(
    private appComponentStore: AppComponentStore,
    private router: Router,
    private signalR: SignalRService,
    private userInfo: UserInfoService,
    private auth: AuthService,
    private fileService: FileService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  public ngOnInit() {
    this.googleAnalyticsService.loadGoogleAnalytics();
    // router events subscription is needed instead of async pipe, because the pipe subscribes too late on initial application load
    this.initRouterEventsSubscription();
    this.initAuthUserSubscription();
    this.signalR.createHubConnection();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private initRouterEventsSubscription() {
    this.router.events
      .pipe(
        tap(event => (this.isRouteLoading = this.isStartEvent(event))),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  private initAuthUserSubscription() {
    const userInfo$ = this.userInfo.getUserInfo().pipe(first());
    const versionFile$ = this.fileService.getAppVersion().pipe(first());

    this.auth.user$
      .pipe(
        first(),
        switchMap(_ => userInfo$),
        tap(userInfo => this.appComponentStore.setCurrentUserType(UserType[userInfo.userRole])),
        switchMap(_ => versionFile$),
        tap(({ version }) => this.appComponentStore.setAppVersion(version))
      )
      .subscribe();
  }

  private isStartEvent(event: Event): boolean {
    return event instanceof RouteConfigLoadStart || event instanceof ResolveStart;
  }
}
