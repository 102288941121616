<div class="property-container" *ngIf="property">
  <span class="label">{{ property.name }}</span>
  <div class="property-content">
    <svg-icon
      *ngIf="property.iconName as icon"
      class="status-icon"
      [name]="icon"
      [lib]="'status-icons'"
      [ngClass]="property.iconClass || ''"
    ></svg-icon>
    <span class="property-value">{{ formattedValue }}</span>
  </div>
</div>
