<div class="header-main">
  <div class="menu-button" (click)="onMenuClick()">
    <div class="menu-icon-wrapper">
      <svg-icon class="menu-icon" name="menu" [ngClass]="{ 'menu-icon-transparent': menuActive }"></svg-icon>
      <svg-icon class="menu-icon" name="clear" [ngClass]="{ 'menu-icon-transparent': !menuActive }"></svg-icon>
    </div>
  </div>
  <img class="d-none d-sm-block logo" src="./assets/images/tecan_missioncontrol_logo.svg" alt="missioncontrol" />
  <img class="d-block d-sm-none logo__small" src="./assets/images/tecan_missioncontrol_logo.svg" alt="missioncontrol" />
</div>
<div class="user">
  <div class="user__info" *ngIf="user">
    <img src="{{ user.picture }}" class="user__image" alt="User Picture" />
    <div class="d-none d-sm-block user__name">
      <div *ngIf="user.name !== user.email; else userNickname">{{ user.name }}</div>
      <ng-template #userNickname>
        <div *ngIf="user.name === user.email">{{ user.nickname }}</div>
      </ng-template>
      <div class="user__email">{{ user.email }}</div>
    </div>
    <svg-icon class="user__logout-icon" name="power_settings_new" (click)="onLogoutClick()"></svg-icon>
  </div>
</div>
