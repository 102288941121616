import { Component, Injector, Input } from '@angular/core';
import { PropertyDisplay } from '~app/shared/models/property-display.model';

@Component({
  selector: 'tmc-property-display',
  templateUrl: './property-display.component.html',
  styleUrls: ['./property-display.component.scss']
})
export class PropertyDisplayComponent {
  @Input() property: PropertyDisplay;

  constructor(private injector: Injector) {}

  get formattedValue() {
    let formattedValue = this.property.value?.toString();

    if (this.property.pipe) {
      const pipe = this.injector.get(this.property.pipe);
      formattedValue = pipe.transform(this.property.value, ...(this.property.pipeArgs || []));
    }

    return !(formattedValue ?? formattedValue === '') ? '-' : formattedValue;
  }
}
