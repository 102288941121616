import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { isArray } from 'lodash-es';

import { ChartColor } from '~app/shared/models/configuration/chart-color.model';
import { StatisticalCardModel } from '~app/shared/models/statistics/statistical-card.model';
import { getNoDataMarker } from '~app/shared/helpers/chart.helper';
import { LineChartOptions } from '~app/shared/utils/charts/line-options.config';
import { ChartColorType } from '~app/shared/utils/charts/chart-color-type.config';
import { StatisticType } from './enums/statistic-type.enum';
import { TrendModel } from '~app/shared/models/statistics/trend.model';
import { Trend } from './trend/trend';
import { TrendBase } from './trend/trend-base.model';
import { TrendInfo } from '~app/shared/enums/trend-info.enum';

@Component({
  selector: 'tmc-statistical-card',
  templateUrl: './statistical-card.component.html',
  styleUrls: ['./statistical-card.component.scss']
})
export class StatisticalCardComponent implements OnChanges, AfterViewInit {
  @ViewChild('canvasChart') canvas: ElementRef;

  @Input() title!: string;
  @Input() utilization: StatisticalCardModel;
  @Input() chartType: ChartType = 'line';
  @Input() isTrendReversed = false;
  @Input() chartColor: ChartColor | ChartColor[] = ChartColorType.secondary;
  @Input() chartOptions: ChartOptions = LineChartOptions;

  public trend: TrendBase | null = null;
  public total: number | string | null = null;
  public canShowTotal = false;
  public canShowTrend = false;
  public canShowNoDataLabel = false;
  public isNoDataInfo = false;
  public noDataLabel = 'No data in 7 days';

  public lineChartColors: Color[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartData: ChartDataSets[] = [{ data: [], lineTension: 0 }];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.utilization?.currentValue) {
      const utilizationData: StatisticalCardModel = changes?.utilization?.currentValue;
      this.total = utilizationData?.total;
      this.lineChartData = this.getChartData(utilizationData);
      this.lineChartLabels = utilizationData.labels;
      this.isNoDataInfo = utilizationData?.trend?.info === TrendInfo.NoData;

      this.setTrendInformation(utilizationData?.trend);
      this.setVisibilityOptions(this.total, this.trend);
    }
  }

  ngAfterViewInit() {
    if (!isArray(this.chartColor)) {
      const chartColor: ChartColor = this.chartColor as ChartColor;
      this.setGradientColors(chartColor.borderColor, chartColor.backgroundColor);
    } else {
      this.lineChartColors = this.chartColor as Color[];
    }

    this.cd.detectChanges();
  }

  private setTrendInformation(trend: TrendModel | null) {
    if (trend !== null) {
      this.trend = new Trend(trend, this.isTrendReversed);
    }
  }

  private setGradientColors(borderColor: string, middleColor: string) {
    const backgroundColor = this.canvas.nativeElement.getContext('2d')?.createLinearGradient(0, 0, 0, 45);
    backgroundColor.addColorStop(0, borderColor);
    backgroundColor.addColorStop(0.1, middleColor);
    backgroundColor.addColorStop(1, 'white');

    this.lineChartColors = [{ borderColor, backgroundColor, borderWidth: 1 }];
  }

  private getChartData(utilizationData: StatisticalCardModel): ChartDataSets[] {
    if (this.chartType === StatisticType.bar) {
      return utilizationData.indices as ChartDataSets[];
    }

    return [{ data: utilizationData.indices, lineTension: 0 }] as ChartDataSets[];
  }

  private setVisibilityOptions(total: any, trend: TrendBase | null) {
    const isCornerCase = this.isCornerCase(trend);
    this.canShowTotal = (total !== null && total !== getNoDataMarker()) || isCornerCase;
    this.canShowTrend = (trend !== null && total !== getNoDataMarker()) || this.isNoDataInfo || isCornerCase;
    this.canShowNoDataLabel = total === getNoDataMarker() && !isCornerCase;
  }

  private isCornerCase(trend: TrendBase | null) {
    return trend !== null && (trend.info === TrendInfo.CornerCase || trend.info === TrendInfo.CornerCaseReversed);
  }
}
