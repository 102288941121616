<div>
  <canvas
    height="250"
    baseChart
    *ngIf="lineChartData"
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
  >
  </canvas>
</div>
