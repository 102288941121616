import { ColorType } from '~app/shared/components/statistical-card/enums/color-type.enum';
import { ChartColor } from '~app/shared/models/configuration/chart-color.model';

const getChartColors = (borderColor: string, backgroundColor: string) => ({
  borderColor,
  backgroundColor,
  hoverBackgroundColor: backgroundColor,
  hoverBorderColor: backgroundColor
});

export const ChartColorType = {
  barPrimary: [getChartColors(ColorType.pink, ColorType.pinkLight)] as ChartColor[],
  secondary: { ...getChartColors(ColorType.green, ColorType.greenLight) } as ChartColor,
  tertiary: { ...getChartColors(ColorType.purple, ColorType.purpleLight) } as ChartColor,
  quaternary: { ...getChartColors(ColorType.yellow, ColorType.yellowLight) } as ChartColor,
  quinary: { ...getChartColors(ColorType.blue, ColorType.blueLight) } as ChartColor,
  multi: [
    getChartColors(ColorType.purple, ColorType.purpleLight),
    getChartColors(ColorType.blue, ColorType.blueLight),
    getChartColors(ColorType.green, ColorType.greenLight),
    getChartColors(ColorType.yellow, ColorType.yellowLight),
    getChartColors(ColorType.pink, ColorType.pinkLight)
  ] as ChartColor[]
};
