import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { UtilizationChartInputData } from '~app/shared/models/statistics/utilization-chart.model';
import { LINE_CHART_OPTIONS, LINE_CHART_TYPE } from './utilization-chart.constants';

@Component({
  selector: 'tmc-utilization-chart',
  templateUrl: './utilization-chart.component.html',
  styleUrls: ['./utilization-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UtilizationChartComponent implements OnChanges {
  @Input() chartData: UtilizationChartInputData;

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: any = LINE_CHART_OPTIONS;
  public lineChartLegend = true;
  public lineChartType: ChartType = LINE_CHART_TYPE;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.chartData) {
      this.lineChartLabels = changes?.chartData.currentValue.labels;
      this.lineChartData = changes?.chartData.currentValue.lineData;
    }
  }
}
