<ngx-datatable
  #dataTable
  [rows]="rows"
  [columns]="columns"
  [columnMode]="columnMode.force"
  [headerHeight]="50"
  [footerHeight]="50"
  [scrollbarH]="scrollbarH"
  [scrollbarV]="scrollbarV"
  [sorts]="sorts"
  [limit]="limit"
  [offset]="offset"
  [count]="count"
  [externalPaging]="externalPaging"
  [externalSorting]="externalSorting"
  [virtualization]="virtualization"
  [rowHeight]="rowHeight"
  [style.height]="height"
  [loadingIndicator]="loadingIndicator"
  (page)="setPage($event)"
  class="material custom"
></ngx-datatable>

<ng-template #dateTimeCellTemplate let-value="value" let-expanded="expanded">
  <span title="{{ value | localeDate }}">{{ value | localeDate }}</span>
</ng-template>

<ng-template #iconCellTemplate let-value="value">
  <span class="icon">
    <svg-icon class="icon-svg" [ngClass]="'message-type-' + value.split('_')[0]" [name]="value" [lib]="'message-icons'"></svg-icon>
  </span>
</ng-template>
