import { Component, Input } from '@angular/core';
import { isArray } from 'lodash-es';
import { PropertyList } from '~app/shared/models/property-list.model';

@Component({
  selector: 'tmc-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss']
})
export class PropertyListComponent {
  public isArray = isArray;
  @Input() list: PropertyList[] = [];
}
