export const ROUTES = {
  DASHBOARD: {
    url: '/dashboard',
    path: 'dashboard'
  },
  DASHBOARD_FULLSCREEN: {
    url: '/dashboard/fullscreen',
    path: 'dashboard/fullscreen'
  },
  INSTRUMENTS: {
    url: '/instruments',
    path: 'instruments'
  },
  TAGS: {
    url: '/tags',
    path: 'tags'
  },
  ERROR: {
    url: '/error',
    path: 'error'
  }
};
