import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ArrayJoin'
})
export class ArrayJoinPipe implements PipeTransform {
  transform(value: any[], ...args: string[]): string {
    const separator = args[0];

    return value.join(separator || ', ');
  }
}
