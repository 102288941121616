import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[tmcMiddleClick]'
})
export class MiddleClickDirective {
  @Output() tmcMiddleClick: EventEmitter<Event> = new EventEmitter();

  private readonly middleMouseKeyId = 1;

  @HostListener('mousedown', ['$event'])
  public mouseDownHandler(event: MouseEvent): void {
    if (event.button === this.middleMouseKeyId) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('mouseup', ['$event'])
  public mouseUpHandler(event: MouseEvent): void {
    if (event.button === this.middleMouseKeyId) {
      event.preventDefault();
      event.stopPropagation();
      this.tmcMiddleClick.emit(event);
    }
  }
}
