import { Injectable } from '@angular/core';
import { find, get, isArray, union } from 'lodash-es';

import {
  calculatePercentageChange,
  calculateMinutesToHours,
  getValueWhenIsInvalid,
  getNoDataMarker
} from '~app/shared/helpers/chart.helper';
import { InstrumentStatisticModel } from '~app/shared/models/instruments/instrument-statistic.model';
import { InstrumentStat } from '~app/shared/models/instruments/instrument-stat.model';
import { StatisticalCardModel } from '~app/shared/models/statistics/statistical-card.model';
import { InstrumentTrend } from '~app/shared/models/instruments/instrument-trend.model';
import { BarCountChart } from '~app/shared/models/statistics/bar-count-chart.model';
import { DiTiCount } from '~app/shared/models/statistics/diti-count.model';
import { LocaleDatePipe } from '~app/shared/pipes/locale-date.pipe';
import { TrendInfo } from '~app/shared/enums/trend-info.enum';

@Injectable({ providedIn: 'root' })
export class StatisticalCardAdapter {
  private statisticMapper = {
    errors: {
      indexKey: 'errorCount',
      trendKey: 'errorCountTrend',
      totalCallback: (utilization: InstrumentStatisticModel) => utilization.errorCount,
      indexCallback: this.adaptCountToBar,
      tooltip: {
        positive: 'Has errors last week',
        negative: 'No errors last week'
      }
    },
    totalRuns: {
      indexKey: 'runCount',
      totalCallback: (utilization: InstrumentStatisticModel) => utilization.runCount
    },
    successfulRuns: {
      indexKey: 'successfulRunsCount',
      trendKey: 'successfulRunsCountTrend',
      totalCallback: this.convertToPercentageChange,
      tooltip: {
        positive: 'Has runs last week',
        negative: 'No runs last week'
      }
    },
    averageDuration: {
      indexKey: 'runTotalMinutes',
      trendKey: 'runTotalMinutesTrend',
      totalCallback: this.convertMinutesToHours,
      tooltip: {
        positive: 'Has Average Duration last week',
        negative: 'No Average Duration last week'
      }
    },
    totalSamples: {
      indexKey: 'sampleCount',
      trendKey: 'sampleCountTrend',
      totalCallback: (utilization: InstrumentStatisticModel) => utilization.sampleCount,
      tooltip: {
        positive: 'Has samples last week',
        negative: 'No samples last week'
      }
    },
    diTiCount: {
      indexKey: 'diTi',
      indexCallback: this.adaptDiTiCount
    }
  };

  constructor(private datePipe: LocaleDatePipe) {}

  public adapt(crudeStatistics: InstrumentStatisticModel[], trend: InstrumentTrend): InstrumentStat {
    const normalizedCharts: InstrumentStat = {
      errors: this.initChartModel(),
      totalRuns: this.initChartModel(),
      successfulRuns: this.initChartModel(),
      averageDuration: this.initChartModel(),
      totalSamples: this.initChartModel(),
      diTiCount: this.initChartModel()
    };

    const labels: string[] = [];
    const summarizedIndices: { [key: string]: number } = {};

    crudeStatistics.forEach(crudeStatisticDay => {
      const date = new Date(crudeStatisticDay.date);
      labels.push(this.datePipe.transform(date, 'EEE') as string);

      Object.entries(normalizedCharts).forEach(([statisticMapperKey]) => {
        const indexKey = get(this.statisticMapper, statisticMapperKey).indexKey;
        const previousValue = get(summarizedIndices, indexKey) || 0;
        const currentValue = get(crudeStatisticDay, indexKey);
        summarizedIndices[indexKey] = previousValue + currentValue;

        const normalizedStatistic: StatisticalCardModel = get(normalizedCharts, statisticMapperKey);
        normalizedStatistic.indices.push(currentValue);
      });
    });

    Object.entries(normalizedCharts).forEach(([statisticMapperKey]) => {
      const statisticMapperChart = get(this.statisticMapper, statisticMapperKey);
      const normalizedChart: StatisticalCardModel = get(normalizedCharts, statisticMapperKey);

      if (this.hasNoValueIndices(normalizedChart)) {
        normalizedChart.total = getNoDataMarker();
        normalizedChart.labels = [];
      } else {
        normalizedChart.total = this.getTotal(statisticMapperChart, summarizedIndices);
        normalizedChart.labels = [...labels];
      }

      normalizedChart.indices = this.normalizeIndices(statisticMapperChart, normalizedChart);
      normalizedChart.trend = this.getTrend(statisticMapperChart, trend);
      this.setTooltip(normalizedChart, statisticMapperChart);
    });

    return { ...normalizedCharts };
  }

  private convertMinutesToHours(utilization: InstrumentStatisticModel): string {
    return calculateMinutesToHours(utilization.runTotalMinutes);
  }

  private convertToPercentageChange(utilization: InstrumentStatisticModel): string {
    return calculatePercentageChange(utilization.runCount, utilization.successfulRunsCount);
  }

  private getTotal(mapper: any, summarizedIndices: any) {
    if (mapper.totalCallback) {
      return getValueWhenIsInvalid(mapper.totalCallback(summarizedIndices), getNoDataMarker());
    }

    return null;
  }

  private getTrend(mapper: any, instrumentTrend: InstrumentTrend) {
    const trend = get(instrumentTrend, mapper.trendKey);
    if (trend === undefined) {
      return null;
    }

    return trend;
  }

  private normalizeIndices(mapper: any, summarizedIndices: StatisticalCardModel) {
    if (mapper.indexCallback) {
      return mapper.indexCallback(summarizedIndices);
    }

    return summarizedIndices.indices;
  }

  private adaptDiTiCount(value: StatisticalCardModel): BarCountChart[] {
    const days = [...value.indices] as DiTiCount[];
    const uniqTypes: string[] = union(
      ...days.map((diTiCounts: any) => diTiCounts.map((diTiCount: DiTiCount) => diTiCount.type))
    );

    const getValues = (type: string): number[] => {
      return days.map((day: any) => {
        const diTiCount: DiTiCount = find(day, { type });

        return diTiCount ? diTiCount.value : 0;
      });
    };

    return uniqTypes.map(type => ({
      data: getValues(type),
      label: type
    }));
  }

  private setTooltip(normalizedChart: StatisticalCardModel, statisticMapperChart: any) {
    if (statisticMapperChart.tooltip && normalizedChart.trend) {
      normalizedChart.trend.tooltip = statisticMapperChart.tooltip;
    }
  }

  private initChartModel(): StatisticalCardModel {
    return {
      total: null,
      trend: { info: TrendInfo.Normal, value: 0 },
      indices: [],
      labels: []
    };
  }

  private adaptCountToBar(value: StatisticalCardModel) {
    return [{ data: value.indices }];
  }

  private hasNoValueIndices(summarizedIndices: StatisticalCardModel): boolean {
    return summarizedIndices.indices.every((i: any) => {
      return isArray(i) && i.length === 0;
    });
  }
}
