import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { asyncScheduler, Observable, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { Message } from '~app/shared/models/instruments/message.model';
import { MessageAdapter } from '~app/shared/adapters/message.adapter';
import { UtilizationMessage } from '~app/shared/models/instruments/utilization-message';
import { LiveMessageResponse } from '~app/shared/models/instruments/live-message-response.model';
import { AppConfigService } from '~app/shared/services/configs/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubUrl = this.appConfigService.api.hubUrl;
  private hubConnection: HubConnection;
  private messageEmitter = new Subject<Message>();
  private utilizationEmitter = new Subject<UtilizationMessage>();

  public liveMessage$: Observable<Message> = this.messageEmitter.asObservable();
  public utilizationMessage$: Observable<UtilizationMessage> = this.utilizationEmitter
    .asObservable()
    .pipe(throttleTime(3000, asyncScheduler, { leading: true, trailing: false }));

  constructor(
    private appConfigService: AppConfigService,
    private authService: AuthService,
    private messageAdapter: MessageAdapter
  ) {}

  public createHubConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.hubUrl}`, {
        transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling, //eslint-disable-line
        accessTokenFactory: () => this.authService.getAccessTokenSilently().toPromise()
      })
      .configureLogging(LogLevel.Trace)
      .withAutomaticReconnect()
      .build();

    this.connect();
  }

  public subscribeToMessages = () => {
    this.hubConnection.on(`messages`, (message: LiveMessageResponse) => {
      this.messageEmitter.next(this.messageAdapter.adapt(message));
    });
  };

  public subscribeToUtilization = () => {
    this.hubConnection.on('utilization', (message: UtilizationMessage) => {
      this.utilizationEmitter.next(message);
    });
  };

  public unsubscribeFromMessages() {
    this.hubConnection.off('messages');
  }

  public unsubscribeFromUtilization() {
    this.hubConnection.off('utilization');
  }

  public connect() {
    this.hubConnection.start().catch(console.error);
  }
}
