<h3 class="title">{{ title | titlecasecustom }}</h3>

<div class="option-container" [ngClass]="{ simpleLabel: isNoDataInfo === false && canShowNoDataLabel }">
  <div *ngIf="canShowNoDataLabel" class="no-data">{{ noDataLabel }}</div>
  <div *ngIf="canShowTotal" class="value">{{ total }}</div>
  <div *ngIf="canShowTrend && trend !== null" class="overview" [ngClass]="'overview--' + trend.signColor">
    <svg-icon class="overview__icon" [ngClass]="trend.signType" [name]="trend.svgName"></svg-icon>
    <span
      class="overview__trend"
      [popover]="trend.tooltipText"
      [containerClass]="trend.signColor"
      triggers="mouseenter:mouseleave"
      >{{ trend?.value }} %</span
    >
  </div>
</div>
<div [ngClass]="{ 'bar-chart': chartType === 'bar' }" class="chart">
  <canvas
    #canvasChart
    baseChart
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="chartOptions"
    [colors]="lineChartColors"
    [legend]="false"
    [chartType]="chartType"
  >
  </canvas>
</div>
