import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Type,
  ComponentFactoryResolver,
  Injector,
  QueryList,
  ViewChildren
} from '@angular/core';

import { ButtonDesign } from '~app/shared/components/button/button-design.model';
import { DialogAction, DialogConfig, DialogButtonConfig, DialogResult } from '~app/shared/components/dialog/models';
import { DIALOG_BUTTON_CONFIGS, DIALOG_RESULT } from '~app/shared/components/dialog/dialog.constants';
import { DialogContentDirective } from './dialog-content.directive';
import { Subject } from 'rxjs/internal/Subject';
import { ButtonComponent } from '~app/shared/components/button/button.component';
import { DialogStore } from '~app/shared/components/dialog/store/dialog-store.service';

@Component({
  selector: 'tmc-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  providers: [DialogStore]
})
export class DialogComponent implements OnInit, AfterViewInit {
  buttonDesign = ButtonDesign;
  dialogConfig: DialogConfig = { title: '' };
  buttons: DialogButtonConfig[] = [];
  contentComponentType: Type<any>;
  contentComponentInstance: Type<any>;
  dialogResult: Subject<DialogResult<any>>;

  @ViewChild(DialogContentDirective, { static: true }) dialogContentHost: DialogContentDirective;
  @ViewChildren(ButtonComponent) buttonList!: QueryList<ButtonComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private store: DialogStore,
    private injector: Injector
  ) {}

  ngOnInit() {
    const buttonConfigs: DialogButtonConfig[] = [];
    if (this.dialogConfig.hasCancelButton) {
      buttonConfigs.unshift(DIALOG_BUTTON_CONFIGS[DialogAction.Cancel]);
    }

    this.dialogConfig.actions?.reduce((acc, action) => {
      const dialogActionButtonConfig = DIALOG_BUTTON_CONFIGS[action];
      if (dialogActionButtonConfig) {
        acc.push(dialogActionButtonConfig);
      }

      return acc;
    }, buttonConfigs);

    this.buttons = [...buttonConfigs, ...(this.dialogConfig.buttons ?? [])];

    this.loadComponent();
  }

  ngAfterViewInit() {
    this.store.setButtons(this.buttonList.toArray());
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.contentComponentType);

    const viewContainerRef = this.dialogContentHost.viewContainerRef;
    viewContainerRef.clear();

    const injector = Injector.create({
      providers: [{ provide: DIALOG_RESULT, useValue: this.dialogResult }],
      parent: this.injector
    });

    const componentRef = viewContainerRef.createComponent(componentFactory, 0, injector);
    this.contentComponentInstance = componentRef.instance;
    Object.assign(componentRef.instance, this.dialogConfig.data ?? {});
  }

  onAction(action: DialogAction) {
    this.dialogResult.next({
      action,
      contentComponentInstance: this.contentComponentInstance
    });
  }

  onClearClick() {
    this.dialogResult.next({ action: DialogAction.Cancel, contentComponentInstance: this.contentComponentInstance });
  }
}
