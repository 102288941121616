import { AfterViewInit, Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';

import { TabService } from '~app/shared/services/tab.service';
import { AppConfigService } from '~app/shared/services/configs/app-config.service';
import { StorageService } from '~app/shared/services';
import { AppComponentStore } from '~app/shared/store/app-component.store';

@Component({
  selector: 'tmc-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements AfterViewInit {
  public isAuthenticated$ = this.auth.isAuthenticated$;
  public menuActive = false;

  constructor(
    public auth: AuthService,
    public tabService: TabService,
    public storageService: StorageService,
    public appComponentStore: AppComponentStore,
    private appConfig: AppConfigService,
    @Inject(DOCUMENT) public document: Document
  ) {}

  public ngAfterViewInit() {
    this.appComponentStore.notifyForAddBlocker();
  }

  public onLogout() {
    this.tabService.clearSavedState();
    this.storageService.clear();
    this.auth.logout({ returnTo: this.appConfig.auth.redirectUri });
  }

  public toggleMenu() {
    this.menuActive = !this.menuActive;
  }

  public dismissMenu() {
    this.menuActive = false;
  }
}
