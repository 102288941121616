import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ButtonComponent } from '~app/shared/components/button/button.component';

export interface DialogState {
  isLoading: boolean;
  buttons: ButtonComponent[];
}

const initialState: DialogState = {
  isLoading: false,
  buttons: []
};

@Injectable()
export class DialogStore extends ComponentStore<DialogState> {
  readonly isLoading$: Observable<boolean> = this.select(state => state.isLoading);
  readonly buttons$: Observable<ButtonComponent[]> = this.select(state => state.buttons).pipe(
    filter(buttons => buttons.length > 0),
    take(1)
  );

  readonly setLoading = this.updater((state, isLoading: boolean) => ({ ...state, isLoading }));
  readonly setButtons = this.updater((state, buttons: ButtonComponent[]) => ({ ...state, buttons }));

  constructor() {
    super(initialState);
  }
}
