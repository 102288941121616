import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { timer } from 'rxjs';

import { NavTab } from '~app/shared/models/nav-tab.model';

import { ScrollContainerComponent } from '../scroll-container/scroll-container.component';

@Component({
  selector: 'tmc-nav-tabset',
  templateUrl: './nav-tabset.component.html',
  styleUrls: ['./nav-tabset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavTabsetComponent implements OnChanges {
  @Input() tabs: NavTab[] | null;
  @Input() activeTab: NavTab | null;

  @Output() clear = new EventEmitter<NavTab>();
  @Output() tabClick = new EventEmitter<NavTab>();

  @ViewChild('scrollContainer', { static: true })
  public scrollContainer: ScrollContainerComponent;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.active) {
      // Wait for rendering of new tabs to complete.
      // Otherwise, there won't be and item to scroll into view
      this.scrollIntoView(changes.active.currentValue.id);
    }
  }

  public onTabClick(tab: NavTab): void {
    if (tab === this.activeTab) {
      return;
    }

    this.tabClick.emit(tab);
  }

  public onClear(event: Event, tab: NavTab, isFirstTab: boolean): void {
    event.stopPropagation();

    if (!isFirstTab) {
      this.clear.emit(tab);
    }
  }

  public scrollIntoView(id: string): void {
    timer().subscribe(() => this.scrollContainer.scrollIntoView(id));
  }
}
