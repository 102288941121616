import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthModule, AuthClientConfig } from '@auth0/auth0-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppConfigService, appConfig } from './shared/services/configs/app-config.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocaleService } from './shared/services/configs/locale.service';
import { ErrorComponent } from './error/error.component';
import { LocaleDatePipe } from './shared/pipes/locale-date.pipe';
import { httpInterceptorProviders } from './shared/interceptors/http-interceptor-providers';

LocaleService.initializeLocaleImport();

@NgModule({
  declarations: [AppComponent, ErrorComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule.forRoot(/* authConfig */),
    HttpClientModule
  ],
  providers: [
    httpInterceptorProviders,
    LocaleDatePipe,
    {
      provide: AppConfigService,
      useValue: appConfig
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appConfig.authConfigInitializer,
      deps: [AuthClientConfig],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
