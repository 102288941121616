export enum ColorType {
  blue = '#11B1E6',
  blueLight = '#22BBEF',
  green = '#9DC52F',
  greenLight = '#95C11F',
  purple = '#550CCB',
  purpleLight = '#6610F2',
  yellow = '#FBC900',
  yellowLight = '#FFCC00',
  pink = '#DA1B77',
  pinkLight = '#EA5198',
  orangeLight = '#F39200'
}
