import { Component, Input } from '@angular/core';

@Component({
  selector: 'svg-icon', // eslint-disable-line
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent {
  @Input() name = '';
  @Input() lib: 'material-icons' | 'status-icons' | 'message-icons' = 'material-icons';
}
