export const calculateMinutesToHours = (minutes: number) => {
  if (hasZerosOnly(minutes)) {
    return getNoDataMarker();
  }

  const averageMinutes = minutes / 7;
  const hrs = getValueWhenIsInvalid(Math.floor(averageMinutes / 60));
  const mins = getValueWhenIsInvalid(Math.floor(averageMinutes % 60));

  return `${hrs} h ${mins} min`;
};

export const calculatePercentageChange = (expected: number, actual: number) => {
  let result = actual / expected;
  result = getValueWhenIsInvalid(result);

  if (hasZerosOnly(expected)) {
    return getNoDataMarker();
  }

  return Math.round(result * 100) + ' %';
};

export const getValueWhenIsInvalid = (result: number, value: any = 0) => {
  return result ? result : value;
};

export const hasZerosOnly = (...args: number[]): boolean => {
  return args.every(v => v === 0);
};

export const getNoDataMarker = () => {
  return '0';
};
