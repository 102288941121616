import { Component, EventEmitter, Input, Output } from '@angular/core';
import { first } from 'rxjs/operators';

import { ROUTES } from '~app/core/constants/routes';
import { AppConfigService } from '~app/shared/services';
import { FileService } from '~app/shared/services/file.service';
import { DEFAULT_TAB, TAGS_TAB } from '~app/shared/services/tab.service';

@Component({
  selector: 'tmc-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
  @Input() appVersion = '';
  @Output() menuButtonClick = new EventEmitter();

  public internalNavLinks = [
    { iconName: 'dashboard', text: 'Dashboard', url: ROUTES.DASHBOARD.url },
    { iconName: 'price-tags', text: 'Tags', url: ROUTES.TAGS.url }
  ];

  public actionLinks = [{ iconName: 'menu_book', text: 'User manual', action: this.downloadUserManual.bind(this) }];

  public externalNavLinks = [
    { iconSource: './assets/images/introspect_logo.png', text: 'Introspect', url: this.appConfig.getLink('introspect') }
  ];

  constructor(private appConfig: AppConfigService, private fileService: FileService) {}

  public getStateForNavigation(url: string) {
    switch (url) {
      case ROUTES.DASHBOARD.url:
        return { navTab: DEFAULT_TAB };
      case ROUTES.TAGS.url:
        return { navTab: TAGS_TAB };
      default:
        return {};
    }
  }

  public onMenuButtonClick() {
    this.menuButtonClick.emit();
  }

  private downloadUserManual() {
    this.onMenuButtonClick();

    this.fileService.downloadUserManual().pipe(first()).subscribe();
  }
}
