<div class="ribbon-container" [class.ribbon-collapsed]="collapsed">
  <div class="ribbon-header" (click)="toggleContent()">
    <svg-icon name="filter_list" [name]="collapsed ? 'filter_list' : 'clear'" class="toggle-icon"></svg-icon>

    <div class="ribbon-selected-filters" [class.invisible]="!collapsed">
      <div *ngFor="let filter of selectedFormValues; index as i" class="ribbon-selected-filter-division">
        <div *ngIf="filter.value">
          <span class="ribbon-selected-filters-label">{{ filter.label }}</span>
          <div
            *ngFor="let value of filter.value"
            class="ribbon-selected-filters-value badge badge-pill"
            [style.background-color]="filterDivisionColors[i]"
          >
            <span *ngIf="filter.formControlName === 'date'">{{ value | date: 'shortDate' }}</span>
            <span *ngIf="filter.formControlName !== 'date'">{{ value.label }}</span>
            <span (click)="removeSelectedFormValue($event, filter.formControlName, value)">×</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ribbon-content">
    <div *ngIf="config" class="filters" [formGroup]="form">
      <ng-container *ngFor="let filter of config.filters">
        <tmc-label class="select" *ngIf="filter.type === ribbonType.dateRangePicker" [label]="filter.label">
          <input
            type="text"
            class="datepicker"
            bsDaterangepicker
            [formControlName]="filter.formControlName"
            [minDate]="filter.dateRange[0] || defaultMinDate"
            [maxDate]="filter.dateRange[1] || defaultMaxDate"
            [bsConfig]="filter.bsConfig"
          />
        </tmc-label>
        <tmc-select
          *ngIf="filter.type === ribbonType.select"
          class="select"
          [label]="filter.label"
          [items]="(filter?.itemsAsync ? (filter?.itemsAsync | async) : filter?.items) || []"
          [formControlName]="filter.formControlName"
          [multiple]="filter.multiple"
          [displayError]="filter.displayError"
        ></tmc-select>
      </ng-container>
    </div>
  </div>
</div>
