import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subscription } from 'rxjs';
import { delay, first, tap, withLatestFrom } from 'rxjs/operators';

import { UserType } from '../enums/user-type.enum';
import { AppError } from '../models/app-error.model';
import { AddBlockerFromComponent } from '~app/modal-templates/google-analytics/add-blocker-form.component';
import { DialogService } from '~app/shared/components/dialog/dialog.service';
import { DialogAction, DialogConfig } from '~app/shared/components/dialog/models';

export interface AppComponentState {
  isLoading: boolean;
  error: AppError | null;
  currentUserType: UserType | null;
  appVersion: string;
  hasAddBlocker: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AppComponentStore extends ComponentStore<AppComponentState> {
  private readonly addBlockerConfig: DialogConfig = {
    title: 'Oops!',
    hasCancelButton: false,
    buttons: [
      {
        action: DialogAction.Ok,
        label: 'Got It!'
      }
    ]
  };

  readonly isLoading$: Observable<boolean> = this.select(state => state.isLoading);
  readonly error$: Observable<AppError | null> = this.select(state => state.error);
  readonly currentUserType$: Observable<UserType | null> = this.select(state => state.currentUserType);
  readonly appVersion$: Observable<string> = this.select(state => state.appVersion);
  readonly hasAddBlocker$: Observable<boolean> = this.select(state => state.hasAddBlocker);

  readonly setLoading = this.updater((state, isLoading: boolean) => ({
    ...state,
    isLoading
  }));

  readonly setError = this.updater((state, error: AppError | null) => ({
    ...state,
    error
  }));

  readonly setCurrentUserType = this.updater((state, currentUserType: UserType) => ({
    ...state,
    currentUserType
  }));

  readonly setAppVersion = this.updater((state, appVersion: string) => ({
    ...state,
    appVersion
  }));

  readonly setHasAddBlocker = this.updater((state, hasAddBlocker: boolean) => ({
    ...state,
    hasAddBlocker
  }));

  readonly notifyForAddBlocker: () => Subscription = this.effect((origin$: Observable<void>) => {
    return origin$.pipe(
      first(),
      delay(400),
      withLatestFrom(this.hasAddBlocker$),
      tap(([_, hasAddBlocker]) => {
        if (hasAddBlocker) {
          this.dialogService.open(AddBlockerFromComponent, this.addBlockerConfig);
        }
      })
    );
  });

  constructor(public dialogService: DialogService) {
    super({ isLoading: false, error: null, currentUserType: null, appVersion: '', hasAddBlocker: false });
  }
}
