<tmc-scroll-container #scrollContainer>
  <ul class="tabset" *ngIf="tabs?.length">
    <li
      *ngFor="let tab of tabs; let first = first"
      class="tab"
      [ngClass]="{ 'tab--selected': activeTab?.id === tab.id }"
      [tmcScrollContainerItem]="tab.id"
      (click)="onTabClick(tab)"
      (tmcMiddleClick)="onClear($event, tab, first)"
    >
      <a class="link">{{ tab.label }}</a>
      <svg-icon name="clear" class="icon-close" *ngIf="!first" (click)="onClear($event, tab, first)"></svg-icon>
    </li>
  </ul>
</tmc-scroll-container>
