import { Injectable } from '@angular/core';
import { capitalize, get } from 'lodash-es';

import { MessageSeverity } from '~app/shared/enums/message-severity.enum';
import { SelectItem } from '~app/shared/models/select-item.model';
import { DateRangeTuple } from '../tuples/date-range-tuple';

@Injectable({
  providedIn: 'root'
})
export class MessageHelperService {
  private messageTypeMap = {
    0: MessageSeverity.other,
    1: MessageSeverity.comment,
    2: MessageSeverity.info,
    3: MessageSeverity.warning,
    4: MessageSeverity.error
  };

  private messageTypeToSvgMap = {
    0: 'other_hexagon-outline',
    1: 'comment_message-circle',
    2: 'info',
    3: 'warning_alert-triangle',
    4: 'error_x-circle'
  };

  public formatDate(date: Date) {
    const dayLeadingZero = ('0' + date.getDate()).slice(-2);
    const monthLeadingZero = ('0' + (date.getMonth() + 1)).slice(-2);

    return `${monthLeadingZero}/${dayLeadingZero}/${date.getFullYear()}`;
  }

  public mapToTypeItems(typeItems: number[]): SelectItem[] {
    return typeItems.map((typeItem: number) => ({
      label: capitalize(this.getMessageTypeBySeverity(typeItem)),
      value: typeItem
    }));
  }

  public mapToDateItems(from: string, to: string): DateRangeTuple {
    const dateFrom = new Date(from);
    const dateTo = new Date(to);

    dateFrom.setDate(dateFrom.getDate() - 1);
    dateTo.setDate(dateTo.getDate() + 1);

    return [dateFrom, dateTo];
  }

  public getMessageTypeBySeverity(severity: number): MessageSeverity {
    return get(this.messageTypeMap, severity);
  }

  public getMessageIconBySeverity(severity: number): string {
    return get(this.messageTypeToSvgMap, severity);
  }
}
