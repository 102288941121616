export enum InstrumentStatus {
  Idle = 'Idle',
  Running = 'Running',
  Paused = 'Paused',
  Stopped = 'Stopped',
  UserInteractionRequired = 'UserInteractionRequired',
  SoftwareNotRunning = 'SoftwareNotRunning',
  Error = 'Error',
  NotConnected = 'NotConnected'
}
