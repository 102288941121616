import { NumberSign } from '~app/shared/enums/number-sign.enum';
import { TrendInfo } from '~app/shared/enums/trend-info.enum';
import { TrendModel } from '~app/shared/models/statistics/trend.model';
import { TrendBase } from './trend-base.model';

export class Trend implements TrendBase {
  public svgName = 'arrow_right_alt';
  public signType: NumberSign = NumberSign.none;
  public signColor: NumberSign = NumberSign.none;
  public isTrendReversed: boolean;
  public tooltipText: string;
  public value: number | undefined;
  public info: TrendInfo;

  private svgToSignMapper = {
    [NumberSign.positive]: this.svgName,
    [NumberSign.negative]: this.svgName,
    [NumberSign.none]: 'horizontal_rule'
  };

  constructor(trend: TrendModel, isTrendReversed = false) {
    this.isTrendReversed = isTrendReversed;
    this.value = trend?.value;
    this.info = trend.info;
    this.setTrendInformation(trend);
  }

  private setTrendInformation(trend: TrendModel | null) {
    if (trend?.info === TrendInfo.CornerCaseReversed) {
      this.setCornerCaseReversed(trend);
    }

    if (trend?.info === TrendInfo.CornerCase) {
      this.setCornerCase(trend);
    }

    if (trend?.info === TrendInfo.Normal) {
      this.setNormalCase(trend);
    }

    if (trend?.info === TrendInfo.NoData) {
      this.setNoDataCase();
    }
  }

  public getSignColor(value: number): NumberSign {
    if (value === 0) {
      return NumberSign.none;
    }

    if (this.isTrendReversed) {
      return value < 0 ? NumberSign.positive : NumberSign.negative;
    }

    return value < 0 ? NumberSign.negative : NumberSign.positive;
  }

  private getSvgName(value: number): string {
    const signType = this.getSignType(value);

    return this.svgToSignMapper[signType];
  }

  private getSignType(value: number): NumberSign {
    if (value === 0) {
      return NumberSign.none;
    }

    return value < 0 ? NumberSign.negative : NumberSign.positive;
  }

  private setCornerCaseReversed(trend: TrendModel) {
    if (trend.value === -100) {
      this.tooltipText = trend.tooltip?.positive || '';
      this.signType = NumberSign.negative;
      this.signColor = NumberSign.positive;
    }

    if (trend.value === 100) {
      this.tooltipText = trend.tooltip?.negative || '';
      this.signType = NumberSign.positive;
      this.signColor = NumberSign.negative;
    }
  }

  private setCornerCase(trend: TrendModel) {
    if (trend.value === -100) {
      this.tooltipText = trend.tooltip?.positive || '';
      this.signType = NumberSign.negative;
      this.signColor = NumberSign.negative;
    }

    if (trend.value === 100) {
      this.tooltipText = trend.tooltip?.negative || '';
      this.signColor = NumberSign.positive;
      this.signType = NumberSign.positive;
    }
  }

  private setNormalCase(trend: TrendModel) {
    this.svgName = this.getSvgName(trend?.value);
    this.signType = this.getSignType(trend?.value);
    this.signColor = this.getSignColor(trend?.value);
  }

  private setNoDataCase() {
    this.tooltipText = 'No data';
    this.signColor = NumberSign.none;
    this.signType = NumberSign.none;
  }
}
