import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { InstrumentStatus } from '~app/shared/enums/instrument-status.enum';
import { Instrument } from '../models/instruments/instrument.model';

@Injectable({
  providedIn: 'root'
})
export class InstrumentHelperService {
  public statusMap: { [key: string]: { iconName: string; message: string; className: string } } = {
    [InstrumentStatus.Idle]: {
      iconName: 'running',
      message: 'Idle',
      className: 'instrument-status-idle'
    },
    [InstrumentStatus.Running]: {
      iconName: 'running',
      message: 'Running',
      className: 'instrument-status-running'
    },
    [InstrumentStatus.Paused]: {
      iconName: 'running-outline',
      message: 'Paused',
      className: 'instrument-status-paused'
    },
    [InstrumentStatus.Stopped]: {
      iconName: 'running-outline',
      message: 'Stopped',
      className: 'instrument-status-stopped'
    },
    [InstrumentStatus.UserInteractionRequired]: {
      iconName: 'user_interaction',
      message: 'Requires User Interaction',
      className: 'instrument-status-user-interaction-required'
    },
    [InstrumentStatus.SoftwareNotRunning]: {
      iconName: 'running-outline',
      message: 'Software Not Running',
      className: 'instrument-status-software-not-running'
    },
    [InstrumentStatus.Error]: {
      iconName: 'running',
      message: 'Error',
      className: 'instrument-status-error'
    },
    [InstrumentStatus.NotConnected]: {
      iconName: 'running',
      message: 'Not Connected',
      className: 'instrument-status-not-connected'
    }
  };

  private defaultStatus = this.statusMap[InstrumentStatus.NotConnected];

  public getStatusIcon(status: keyof typeof InstrumentStatus): string {
    return this.statusMap[status]?.iconName || this.defaultStatus.iconName;
  }

  public getStatusMessage(status: keyof typeof InstrumentStatus): string {
    return this.statusMap[status]?.message || this.defaultStatus.message;
  }

  public getStatusLabel(status: keyof typeof InstrumentStatus): string {
    return status === 'Running' ? 'Estimated End Time:' : 'Status:';
  }

  public getStatusLabelValue(instrument: Instrument): string {
    if (instrument.instrumentStatus === InstrumentStatus.Running) {
      return instrument.runEstimatedEndTime ? formatDate(instrument.runEstimatedEndTime, 'HH:mm:ss', 'en-EN') : '-';
    } else {
      return instrument.instrumentStatus
        ? this.statusMap[instrument.instrumentStatus]?.message
        : this.defaultStatus.message;
    }
  }

  public getStatusClassName(status: keyof typeof InstrumentStatus): string {
    return this.statusMap[status]?.className || this.defaultStatus.className;
  }
}
