import { Injectable } from '@angular/core';
import { AuthConfig, AuthClientConfig } from '@auth0/auth0-angular';

import { ApiConfig } from '~app/shared/models/configuration/api-config.model';
import { AppConfig } from '~app/shared/models/configuration/app-config.model';

@Injectable()
export class AppConfigService {
  public configurationData: AppConfig;

  async load(): Promise<void> {
    const data = await fetch('/configuration.json').then(response => response.json());
    this.configurationData = data;
  }

  public get api(): ApiConfig {
    return this.configurationData?.api;
  }

  public get baseApiPath(): string {
    if (this.api) {
      const { protocol, host, path } = this.api;

      return `${protocol}://${host}/${path}`;
    }

    return '';
  }

  public get auth(): AuthConfig {
    return this.configurationData?.auth;
  }

  public get gaTrackingId(): string {
    return this.configurationData?.gaTrackingId;
  }

  public getLink(key: string): string {
    return this.configurationData?.links[key] ?? '';
  }

  public authConfigInitializer = (config: AuthClientConfig) => () =>
    this.load().then(() => {
      const { httpInterceptor } = this.api;

      const configuration = {
        ...this.auth,
        skipRedirectCallback:
          window.location.href.includes('error') && window.location.href.includes('error_description'),
        httpInterceptor
      };

      config.set(configuration);
    });
}

export const appConfig = new AppConfigService();
