import { Injectable } from '@angular/core';

import { AppComponentStore } from '~app/shared/store/app-component.store';
import { AppConfigService } from '~app/shared/services/configs/app-config.service';

declare let gtag: (event: string, eventName: string, parameters: any) => void;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor(private appComponentStore: AppComponentStore, private appConfig: AppConfigService) {}

  public loadGoogleAnalytics() {
    const gaTrackingId = this.appConfig.gaTrackingId;

    if (gaTrackingId) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;

      gTagManagerScript.onerror = () => {
        this.appComponentStore.setHasAddBlocker(true);
      };

      document.head.appendChild(gTagManagerScript);

      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${gaTrackingId}');
      `;
      document.head.appendChild(gaScript);
    }
  }

  public logEvent(eventName: string, tecanCategory: string) {
    gtag('event', eventName, {
      tecan_category: tecanCategory
    });
  }
}
