import { ActivatedRouteSnapshot, CanActivate, CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { SignalRService } from '~app/shared/services/signalr.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrSubscriptionGuard implements CanActivate, CanDeactivate<any> {
  constructor(private signalR: SignalRService) {}

  canActivate(_route: ActivatedRouteSnapshot) {
    this.signalR.subscribeToMessages();
    this.signalR.subscribeToUtilization();

    return true;
  }

  canDeactivate(_component: any, _route: ActivatedRouteSnapshot) {
    this.signalR.unsubscribeFromMessages();
    this.signalR.unsubscribeFromUtilization();

    return true;
  }
}
