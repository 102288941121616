<div class="form-group">
  <label class="control-label" [hidden]="!label" [for]="id" (click)="focus()">{{ label }}</label>
  <ng-select
    #select
    [id]="id"
    class="tmc-select"
    [items]="items"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [loading]="loading"
    [multiple]="multiple"
    [clearable]="clearable"
    [closeOnSelect]="!multiple"
    [searchable]="searchable"
    [placeholder]="placeholder"
    [compareWith]="compareWith"
    [readonly]="readonly"
    [ngModel]="ngModel"
    (ngModelChange)="change($event)"
    (change)="changed.emit($event)"
    (open)="opened.emit()"
    (close)="closed.emit()"
  >
  </ng-select>
  <div class="control__error" [class.d-none]="!displayError">
    {{ getErrorMessage() }}
  </div>
</div>
