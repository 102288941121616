import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { TabDirective, TabsetComponent, TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { StatisticalCardComponent } from '~app/shared/components/statistical-card/statistical-card.component';
import { PropertyListComponent } from '~app/shared/components/property-list/property-list.component';
import { SvgIconComponent } from '~app/shared/components/svg-icon/svg-icon.component';
import { MiddleClickDirective } from '~app/shared/directives/middle-click.directive';
import { ExecutionStatusComponent } from '~app/shared/components/execution-status/execution-status.component';
import { MapModule } from '~app/shared/components/map/map.module';
import { UtilizationChartComponent } from '~app/shared/components/utilization-chart/utilization-chart.component';
import { SpinnerComponent } from '~app/shared/components/spinner/spinner.component';
import { SimpleTableComponent } from '~app/shared/components/simple-table/simple-table.component';
import { InputComponent } from '~app/shared/components/input/input.component';
import { SelectComponent } from '~app/shared/components/select/select.component';
import { ButtonComponent } from '~app/shared/components/button/button.component';
import { ArrayJoinPipe } from '~app/shared/pipes/array-join.pipe';
import { DialogComponent } from '~app/shared/components/dialog/dialog.component';
import { DialogContentDirective } from '~app/shared/components/dialog/dialog-content.directive';
import { LocaleDatePipe } from '~app/shared/pipes/locale-date.pipe';
import { InstrumentStatisticsChartsComponent } from './components/instrument-statistics-charts/instrument-statistics-charts.component';
import { PropertyDisplayComponent } from '~app/shared/components/property-display/property-display.component';
import { FilterRibbonComponent } from '~app/shared/components/filter-ribbon/filter-ribbon.component';
import { GridTableComponent } from '~app/shared/components/grid-table/grid-table.component';
import { ToggleFullscreenDirective } from '~app/shared/directives/toggleFullscreen.directive';
import { TitleCaseCustomPipe } from './pipes/title-case-custom.pipe';
import { LabelComponent } from './components/label/label.component';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    MapModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    RoundProgressModule,
    NgxDatatableModule
  ],
  declarations: [
    SvgIconComponent,
    StatisticalCardComponent,
    MiddleClickDirective,
    ToggleFullscreenDirective,
    PropertyListComponent,
    ExecutionStatusComponent,
    UtilizationChartComponent,
    SpinnerComponent,
    SimpleTableComponent,
    SelectComponent,
    InputComponent,
    ButtonComponent,
    ArrayJoinPipe,
    DialogComponent,
    DialogContentDirective,
    LocaleDatePipe,
    InstrumentStatisticsChartsComponent,
    PropertyDisplayComponent,
    FilterRibbonComponent,
    GridTableComponent,
    TitleCaseCustomPipe,
    LabelComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MapModule,
    NgSelectModule,
    RoundProgressModule,
    SvgIconComponent,
    StatisticalCardComponent,
    MiddleClickDirective,
    ToggleFullscreenDirective,
    PropertyListComponent,
    ExecutionStatusComponent,
    TabsetComponent,
    TabDirective,
    UtilizationChartComponent,
    SpinnerComponent,
    SimpleTableComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    ArrayJoinPipe,
    DialogComponent,
    DialogContentDirective,
    LocaleDatePipe,
    InstrumentStatisticsChartsComponent,
    PropertyDisplayComponent,
    FilterRibbonComponent,
    GridTableComponent,
    TitleCaseCustomPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
