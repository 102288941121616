import { Injectable, Type } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogComponent } from '~app/shared/components/dialog/dialog.component';
import { DialogAction, DialogConfig, DialogResult } from '~app/shared/components/dialog/models';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private bsModalRef: BsModalRef | null = null;

  constructor(private modalService: BsModalService) {}

  open<T>(contentComponentType: Type<T>, dialogConfig: DialogConfig<T>): Observable<DialogResult<T>> {
    const dialogResult = new Subject<DialogResult<T>>();

    this.bsModalRef = this.modalService.show(DialogComponent, {
      class: 'modal-dialog-centered',
      initialState: { dialogConfig, contentComponentType, dialogResult }
    });

    this.bsModalRef.onHide.pipe(take(1)).subscribe(() => {
      dialogResult.complete();
    });

    dialogResult.subscribe(result => {
      if (result.action === DialogAction.Cancel) {
        this.close();
      }
    });

    return dialogResult.asObservable();
  }

  close() {
    this.bsModalRef?.hide();
    this.bsModalRef = null;
  }
}
