import { map } from 'lodash-es';

import { SelectItem } from '~app/shared/models/select-item.model';

export const valueLabelMapper: (value: any) => SelectItem<string> = (value: any) => {
  const label = value?.toString() || '';

  return { value: label, label };
};

export const toSelectItems: (propertyPath: string, arr: any[]) => SelectItem<string>[] = (
  propertyPath: string,
  arr: any[] = []
) => [...new Set(map(arr, propertyPath))].map(valueLabelMapper);

export const generateId = () =>
  [...crypto.getRandomValues(new Uint32Array(3))].map(item => item.toString(36)).join('-');
