import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ROUTES } from '~app/core/constants/routes';
import { AppComponentStore } from '../store/app-component.store';
import { AppConfigService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class ErrorGuard implements CanActivate {
  constructor(
    private appComponentStore: AppComponentStore,
    private router: Router,
    private authService: AuthService,
    private appConfig: AppConfigService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (route.queryParamMap.has('error') && route.queryParamMap.has('error_description')) {
      this.appComponentStore.setError({
        type: route.queryParamMap.get('error') ?? '',
        description: route.queryParamMap.get('error_description') ?? ''
      });

      if (route.queryParamMap.get('error') === 'unauthorized' && !route.queryParamMap.has('skipLogout')) {
        const path = this.router
          .createUrlTree([], { queryParams: { ...route.queryParams, skipLogout: true } })
          .toString()
          .substr(1);

        this.authService.logout({
          returnTo: `${this.appConfig.auth.redirectUri}${path}`
        });

        return false;
      }

      return this.router.createUrlTree([ROUTES.ERROR.url], { queryParams: route.queryParams });
    }

    return true;
  }
}
