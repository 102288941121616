import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';

import { MapComponent } from '~app/shared/components/map/map.component';
import { ToMarkersPipe } from '~app/shared/components/map/to-markers.pipe';

@NgModule({
  declarations: [MapComponent, ToMarkersPipe],
  imports: [CommonModule, LeafletModule, LeafletMarkerClusterModule],
  exports: [MapComponent, ToMarkersPipe],
  bootstrap: [MapComponent]
})
export class MapModule {}
