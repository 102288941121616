<div class="modal-header">
  <h2 class="modal-title pull-left">{{ dialogConfig?.title }}</h2>
  <svg-icon name="clear" class="icon-close" (click)="onClearClick()"></svg-icon>
</div>
<div class="modal-body">
  <ng-template tmcDialogContentHost></ng-template>
</div>
<div class="modal-footer">
  <tmc-button
    *ngFor="let button of buttons"
    class="modal-footer-button"
    [label]="button.label"
    [design]="button.design || buttonDesign.Primary"
    [iconName]="button.iconName || ''"
    (clicked)="onAction(button.action)"
  ></tmc-button>
</div>
