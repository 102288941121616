import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '~app/shared/services/configs/app-config.service';
import { UserInfo } from '~app/shared/models/user-info.model';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private basePath = `${this.configService.baseApiPath}/users/userinfo`;

  constructor(private configService: AppConfigService, private http: HttpClient) {}

  public getUserInfo(): Observable<UserInfo> {
    const url = `${this.basePath}`;

    return this.http.get<UserInfo>(url);
  }
}
