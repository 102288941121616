import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(private http: HttpClient) {}

  downloadUserManual(): Observable<any> {
    return this.http
      .get('/files/user_manual.pdf', {
        responseType: 'arraybuffer'
      })
      .pipe(
        tap((response: any) => {
          const blob: any = new Blob([response], { type: 'application/pdf;charset=utf-8' });
          fileSaver.saveAs(blob, 'user_manual.pdf');
        })
      );
  }

  getAppVersion(): Observable<any> {
    return this.http.get('/version.json');
  }
}
