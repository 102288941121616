import { Injectable } from '@angular/core';

import { Message } from '~app/shared/models/instruments/message.model';
import { LiveMessageResponse } from '~app/shared/models/instruments/live-message-response.model';
import { MessageHelperService } from '~app/shared/services/message-helper.service';

@Injectable({
  providedIn: 'root'
})
export class MessageAdapter {
  constructor(private messageHelper: MessageHelperService) {}

  adapt(item: LiveMessageResponse): Message {
    return {
      ...item,
      dateTime: new Date(item.dateTime),
      messageSeverity: this.messageHelper.getMessageTypeBySeverity(item.messageSeverity),
      startTime: item.startTime ? new Date(item.startTime) : undefined,
      estimatedEndTime: item.estimatedEndTime ? new Date(item.estimatedEndTime) : undefined,
      svgName: this.messageHelper.getMessageIconBySeverity(item.messageSeverity)
    };
  }
}
