<section class="statistics" [ngClass]="customClasses" *ngIf="stats">
  <tmc-statistical-card
    *ngIf="stats.errors"
    [title]="'error count'"
    [utilization]="stats.errors"
    [chartType]="cardType.bar"
    [chartOptions]="countBarChartOptions"
    [chartColor]="chartColorType.barPrimary"
    [isTrendReversed]="true"
  ></tmc-statistical-card>
  <tmc-statistical-card
    *ngIf="stats.successfulRuns"
    [title]="'run success rate'"
    [utilization]="stats.successfulRuns"
    [chartColor]="chartColorType.secondary"
  ></tmc-statistical-card>
  <tmc-statistical-card
    *ngIf="stats.averageDuration"
    [title]="'average daily runtime'"
    [utilization]="stats.averageDuration"
    [chartColor]="chartColorType.quaternary"
  ></tmc-statistical-card>
  <tmc-statistical-card
    *ngIf="stats.totalSamples"
    [title]="'sample count'"
    [utilization]="stats.totalSamples"
    [chartColor]="chartColorType.quinary"
  ></tmc-statistical-card>
  <tmc-statistical-card
    *ngIf="stats.diTiCount"
    [title]="'diTi count'"
    [utilization]="stats.diTiCount"
    [chartType]="cardType.bar"
    [chartOptions]="barChartOptions"
    [chartColor]="chartColorType.multi"
  ></tmc-statistical-card>
</section>
