import { Component, Input } from '@angular/core';
import { CustomControl } from '~app/shared/services/base/custom-control';

@Component({
  selector: 'tmc-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends CustomControl {
  @Input() label = '';
  @Input() placeholder = '';

  public onBlur(event: Event) {
    this.onTouch(event);
  }
}
