import { ChartType } from 'chart.js';

export const LINE_CHART_TYPE: ChartType = 'line';

export const MONTHS: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const CHART_COLORS = {
  min: {
    backgroundColor: 'rgba(255,59,0,0.3)',
    borderColor: '#f4511e',
    pointBackgroundColor: 'rgba(255,59,0,1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(255,59,0,0.8)'
  },
  average: {
    backgroundColor: 'rgba(255,255,0,0.3)',
    borderColor: '#ffc107',
    pointBackgroundColor: 'rgba(255,255,0,1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(255,255,0,0.8)'
  },
  max: {
    backgroundColor: 'rgba(9,255,0,0.3)',
    borderColor: '#7cb342',
    pointBackgroundColor: 'rgba(9,255,0,1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(9,255,0,0.8)'
  }
};

export const LINE_CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{}],
    yAxes: [
      {
        id: 'y-axis-0',
        position: 'left'
      }
    ]
  },
  tooltips: {
    callbacks: {
      title: (tooltipItem: any, data: any): string => `Runtime: ${tooltipItem[0].yLabel} hours`,
      label: (tooltipItem: any, data: any): string => {
        return `Date: ${tooltipItem.xLabel}`;
      }
    }
  }
};
