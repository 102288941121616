<div *ngIf="isAuthenticated$ | async">
  <tmc-header
    class="header"
    (logout)="onLogout()"
    (menuClick)="toggleMenu()"
    [menuActive]="menuActive"
    [user]="auth.user$ | async"
  ></tmc-header>

  <tmc-side-menu
    class="side-menu"
    [ngClass]="{ active: menuActive }"
    [appVersion]="(appComponentStore.appVersion$ | async) || ''"
    (menuButtonClick)="dismissMenu()"
  ></tmc-side-menu>

  <div class="main-content" (click)="dismissMenu()">
    <tmc-nav-tabset
      [tabs]="tabService.tabs$ | async"
      [activeTab]="tabService.activeTab$ | async"
      (clear)="tabService.clear($event)"
      (tabClick)="tabService.navigateByTab($event)"
    ></tmc-nav-tabset>
    <div class="content-wrapper">
      <router-outlet class="router-outlet"></router-outlet>
    </div>
  </div>
</div>
