import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { ROUTES } from '~app/core/constants/routes';

import { NavTab } from '~app/shared/models/nav-tab.model';
import { StorageService } from '~app/shared/services/storage.service';

const TABS_STORAGE_KEY = 'tabs';
const ACTIVE_TAB_STORAGE_KEY = 'activeTab';

export const DEFAULT_TAB: NavTab = {
  id: ROUTES.DASHBOARD.path,
  label: ROUTES.DASHBOARD.path,
  url: ROUTES.DASHBOARD.url
};

export const TAGS_TAB: NavTab = {
  id: ROUTES.TAGS.path,
  label: ROUTES.TAGS.path,
  url: ROUTES.TAGS.url
};

@Injectable({
  providedIn: 'root'
})
export class TabService implements OnDestroy {
  private destroyed$ = new Subject();
  private routerNavigationEnd$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

  public tabs$: BehaviorSubject<NavTab[]>;
  public activeTab$: BehaviorSubject<NavTab>;

  constructor(private router: Router, private storageService: StorageService) {
    this.initTabs();
    this.subscribeToRouterNavigationEnd();
  }

  public navigateByTab(navTab: NavTab) {
    if (this.router.url === navTab.url && this.activeTab$.value.url !== navTab.url) {
      this.activate(navTab);
    } else {
      this.router.navigateByUrl(navTab.url || '', { state: { navTab } });
    }
  }

  public clear(navTab: NavTab) {
    const tabs = this.tabs$.getValue();
    const activeTab = this.activeTab$.getValue();
    const previousTabIndex = tabs.findIndex(tab => tab.id === navTab.id) - 1;
    this.tabs$.next(tabs.filter(tab => tab.id !== navTab.id));
    this.saveTabs();

    if (activeTab.id === navTab.id && previousTabIndex >= 0) {
      this.navigateByTab(this.tabs$.getValue()[previousTabIndex]);
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next();
  }

  private initTabs() {
    const initialTabs = this.storageService.getFromSession<NavTab[]>(TABS_STORAGE_KEY) || [DEFAULT_TAB];
    const initialActiveTab = this.storageService.getFromSession<NavTab>(ACTIVE_TAB_STORAGE_KEY) || DEFAULT_TAB;

    this.tabs$ = new BehaviorSubject<NavTab[]>(initialTabs);
    this.activeTab$ = new BehaviorSubject<NavTab>(initialActiveTab);
  }

  private subscribeToRouterNavigationEnd() {
    this.routerNavigationEnd$
      .pipe(
        map(() => this.getTabFromCurrentNavigation()),
        tap((navTab: NavTab | null) => navTab && this.activate(navTab)),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  private getTabFromCurrentNavigation(): NavTab | null {
    const currentNavigation = this.router.getCurrentNavigation();
    const navTab = currentNavigation?.extras?.state?.navTab ?? this.parseTabFromUrl();

    if (navTab) {
      return {
        url: currentNavigation?.extractedUrl.toString(),
        ...navTab
      };
    }

    return null;
  }

  private activate(navTab: NavTab) {
    const tabs = this.tabs$.getValue();
    let activeTab = tabs.find(tab => tab.id === navTab.id);

    if (!activeTab) {
      this.tabs$.next([...tabs, navTab]);
      this.saveTabs();
      activeTab = navTab;
    }

    this.activeTab$.next(activeTab);
    this.saveActiveTab();
  }

  private saveTabs() {
    this.storageService.storeInSession(TABS_STORAGE_KEY, this.tabs$.getValue());
  }

  private saveActiveTab() {
    this.storageService.storeInSession(ACTIVE_TAB_STORAGE_KEY, this.activeTab$.getValue());
  }

  private parseTabFromUrl(): NavTab | null {
    const path = this.router.url.split('?')[0];

    if (path === ROUTES.TAGS.url) {
      return TAGS_TAB;
    }

    return null;
  }

  clearSavedState() {
    this.storageService.removeFromSession(TABS_STORAGE_KEY);
    this.storageService.removeFromSession(ACTIVE_TAB_STORAGE_KEY);
  }
}
