import { Component, EventEmitter, Input, Output } from '@angular/core';

import { User } from '~app/shared/models/user.model';

@Component({
  selector: 'tmc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() user: User;
  @Input() menuActive: boolean;

  @Output() logout = new EventEmitter();
  @Output() menuClick = new EventEmitter();

  public onLogoutClick() {
    this.logout.emit();
  }

  public onMenuClick() {
    this.menuClick.emit();
  }
}
