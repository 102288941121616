<div class="container-fluid">
  <div class="row" *ngFor="let item of list">
    <div class="col col-auto property">{{ item.property }}</div>
    <ng-container
      *ngTemplateOutlet="isArray(item.value) ? valuesTemplate : valueTemplate; context: { $implicit: item.value }"
    >
    </ng-container>
  </div>
</div>

<ng-template #valueTemplate let-value>
  <div class="col">{{ value }}</div>
</ng-template>
<ng-template #valuesTemplate let-values>
  <div *ngFor="let value of values" class="col">{{ value }}</div>
</ng-template>
