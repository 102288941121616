import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';

export abstract class LocaleService {
  private static locale = '';

  private static SetLocale(vale: string) {
    LocaleService.locale = vale;
  }

  public static get localeId(): string {
    return LocaleService.locale;
  }

  public static readonly initializeLocaleImport = () => {
    const language = navigator.languages[0];

    return import(`@angular/common/locales/${language}.js`)
      .then(module => {
        registerLocaleData(module.default);
        LocaleService.SetLocale(module.default[0]);
      })
      .catch(_ => {
        registerLocaleData(localeEn);
        LocaleService.SetLocale('en');
      });
  };
}
