import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from '../models/tag.model';
import { AppConfigService } from './configs/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  private basePath = `${this.configService.baseApiPath}/tags`;

  constructor(private configService: AppConfigService, private http: HttpClient) {}

  public getTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(this.basePath);
  }

  public createTag(tag: Partial<Tag>): Observable<void> {
    return this.http.post<void>(this.basePath, tag);
  }

  public updateTag(tag: Partial<Tag>): Observable<void> {
    return this.http.put<void>(this.basePath, tag);
  }

  public deleteTag(id: number): Observable<void> {
    return this.http.delete<void>(this.basePath, { params: { id: `${id}` } });
  }

  public assignTag(tagId: number, instrumentIds: number[]): Observable<void> {
    const url = `${this.basePath}/${tagId}/assign`;

    return this.http.post<void>(url, instrumentIds);
  }

  public unassignTag(tagId: number, instrumentIds: number[]): Observable<void> {
    const url = `${this.basePath}/${tagId}/unassign`;

    return this.http.post<void>(url, instrumentIds);
  }
}
