import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, first, mapTo, takeUntil } from 'rxjs/operators';

import { ScrollContainerItemDirective } from './scroll-container-item.directive';

@Component({
  selector: 'tmc-scroll-container',
  templateUrl: './scroll-container.component.html',
  styleUrls: ['./scroll-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollContainerComponent implements OnDestroy, AfterViewInit {
  public scrollLeftEnabled: boolean;
  public scrollRightEnabled: boolean;
  public get viewport(): HTMLElement {
    return this.viewportRef.nativeElement;
  }

  @ViewChild('viewport', { static: true })
  private viewportRef: ElementRef<HTMLElement>;

  @ContentChildren(ScrollContainerItemDirective, { descendants: true })
  private items: QueryList<ScrollContainerItemDirective>;

  private scrollStep = 150;

  private get hasOverflow() {
    return this.viewport.scrollWidth > this.viewport.clientWidth;
  }

  private destroyed$ = new Subject<void>();
  private afterViewInit$ = new BehaviorSubject<boolean>(false);

  private timeToAccumulateChanges = 100;
  private scrollSpeedMultiplier = 2;

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    const scrolled$ = fromEvent(this.viewport, 'scroll', { passive: true });
    const resized$ = fromEvent(window, 'resize', { passive: true });
    const initial$ = of(null);
    const itemsChanged$ = this.items.changes;
    const shouldRecalculate$ = merge(scrolled$, resized$, initial$, itemsChanged$).pipe(
      // We need to debounce the events to avoid performance issues
      debounceTime(this.timeToAccumulateChanges),
      takeUntil(this.destroyed$)
    );

    shouldRecalculate$.subscribe(() => this.recalculateScrollEnabled());

    this.afterViewInit$.next(true);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public scrollLeft(): void {
    if (this.scrollLeftEnabled) {
      this.viewport.scrollBy({
        left: -this.scrollStep,
        behavior: 'smooth'
      });
    }
  }

  public scrollRight(): void {
    if (this.scrollRightEnabled) {
      this.viewport.scrollBy({
        left: this.scrollStep,
        behavior: 'smooth'
      });
    }
  }

  public scrollIntoView(dataItem: any): void {
    this.delayAfterViewInit().subscribe(() => {
      const itemToBringIntoView = this.findItem(dataItem);
      if (!itemToBringIntoView) {
        throw new Error('Cannot find item to scroll to.');
      }

      const element = itemToBringIntoView.elementRef.nativeElement;
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    });
  }

  public onWheel($event: WheelEvent): void {
    if (this.hasOverflow) {
      $event.preventDefault();
      this.viewport.scrollBy({
        left: this.getScrollDelta($event) * this.scrollSpeedMultiplier
      });
    }
  }

  private recalculateScrollEnabled(): void {
    this.scrollLeftEnabled = this.canScrollLeft();
    this.scrollRightEnabled = this.canScrollRight();
    this.changeDetector.detectChanges();
  }

  private findItem(dataItem: any): ScrollContainerItemDirective | undefined {
    return this.items.find(item => item.tmcScrollContainerItem === dataItem);
  }

  private canScrollLeft(): boolean {
    return this.hasOverflow && this.viewport.scrollLeft > 0;
  }

  private canScrollRight(): boolean {
    const scrollRight = this.viewport.scrollWidth - (this.viewport.scrollLeft + this.viewport.clientWidth);

    return this.hasOverflow && scrollRight > 0;
  }

  private delayAfterViewInit(): Observable<null> {
    return this.afterViewInit$.asObservable().pipe(filter(Boolean), first(), mapTo(null));
  }

  private getScrollDelta($event: WheelEvent): number {
    const { deltaX, deltaY } = $event;
    // The primary axis is the one with the largest movement.
    // It is possible to have delta on multiple axes when using a touch pad.
    const isXAxisPrimary = Math.abs(deltaX) > Math.abs(deltaY);

    return isXAxisPrimary ? deltaX : deltaY;
  }
}
