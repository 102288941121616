import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

import { Instrument } from '~app/shared/models/instruments/instrument.model';
import { AppConfigService } from '~app/shared/services/configs/app-config.service';
import { MessageAdapter } from '~app/shared/adapters/message.adapter';
import { StatisticalCardAdapter } from '~app/shared/adapters/statistical-card.adapter';
import { Message } from '~app/shared/models/instruments/message.model';
import { InstrumentsFilter } from '~app/shared/models/instruments/instruments.filter.model';
import { InstrumentStatus } from '~app/shared/enums/instrument-status.enum';
import { InstrumentStat } from '~app/shared/models/instruments/instrument-stat.model';
import { QualityControl } from '~app/shared/models/instruments/quality-control.model';
import { SelectItem } from '~app/shared/models/select-item.model';
import { MessageType } from '~app/shared/enums/message-type.enum';
import { MessagesRequest } from '~app/instruments/models/messages-request.model';
import { InstrumentMessages } from '~app/instruments/models/instrument-message.model';
import { MessageInfo } from '../models/instruments/message-info.model';

const instrumentStatusValues = [...Object.values(InstrumentStatus)];

@Injectable({
  providedIn: 'root'
})
export class InstrumentService {
  private basePath = `${this.configService.baseApiPath}/instruments`;
  private baseStatisticsPath = `${this.configService.baseApiPath}/statistics`;

  constructor(
    private configService: AppConfigService,
    private http: HttpClient,
    private messageAdapter: MessageAdapter,
    private cardAdapter: StatisticalCardAdapter
  ) {}

  public getInstruments(filter: Partial<InstrumentsFilter> = {}): Observable<Instrument[]> {
    return this.http
      .post<Instrument[]>(this.basePath, this.normalizeInstrumentsFilter(filter))
      .pipe(map(instruments => instruments.map(this.normalizeInstrument)));
  }

  public getInstrument(id: string): Observable<Instrument> {
    const url = `${this.basePath}/${id}`;

    return this.http.get<Instrument>(url).pipe(map(this.normalizeInstrument));
  }

  public getInstrumentStat(id: string | number): Observable<InstrumentStat> {
    const url = `${this.baseStatisticsPath}/${id}`;

    return this.http
      .get<any>(url)
      .pipe(map(({ instrumentStats, trends }) => this.cardAdapter.adapt(instrumentStats, trends)));
  }

  public getInstrumentsStats(instrumentIds: number[] | string[] = []): Observable<InstrumentStat> {
    const url = this.baseStatisticsPath;

    return this.http
      .post<any>(url, { instrumentIds })
      .pipe(map(({ instrumentStats, trends }) => this.cardAdapter.adapt(instrumentStats, trends)));
  }

  public getMessageInfo(messagesRequest: MessagesRequest): Observable<MessageInfo> {
    const url = `${this.basePath}/messages`;

    return this.http.post<Message[]>(url, messagesRequest).pipe(
      map(
        (response: any): MessageInfo => ({
          recordsCount: response.recordCount,
          messages: response.liveMessages.map((message: any) => this.messageAdapter.adapt(message))
        })
      )
    );
  }

  public getMessageFilters() {
    const url = `${this.basePath}/message-filters`;

    return this.http.get<InstrumentMessages>(url);
  }

  public patchInstrument(instrument: Instrument, message: Message): Instrument {
    const { content, startTime, estimatedEndTime, progressBarPercentage } = message;
    if (message.messageType === MessageType.RunInfo) {
      return {
        ...instrument,
        runStartTime: startTime,
        runEstimatedEndTime: estimatedEndTime,
        runProgressBarPercentage: progressBarPercentage
      };
    }

    return {
      ...instrument,
      instrumentStatus: content as InstrumentStatus
    };
  }

  private normalizeInstrument(instrument: Instrument): Instrument {
    const normalizeQCDate = (qc: QualityControl): QualityControl => ({
      ...qc,
      executionTime: new Date(qc.executionTime)
    });

    return {
      ...instrument,
      qcData: instrument.qcData ? instrument.qcData.map(normalizeQCDate) : [],
      runStartTime: instrument.runStartTime ? new Date(instrument.runStartTime) : undefined,
      runEstimatedEndTime: instrument.runEstimatedEndTime ? new Date(instrument.runEstimatedEndTime) : undefined,
      instrumentStatus: instrumentStatusValues[instrument.state]
    };
  }

  private normalizeInstrumentsFilter(filter: Partial<InstrumentsFilter>): any {
    let requestFilter = cloneDeep(filter) as any;
    if (filter.labs) {
      requestFilter = {
        ...filter,
        labIds: filter.labs.map((selectItem: SelectItem<string>) => selectItem.value)
      };

      delete requestFilter.labs;
    }

    if (filter.tags) {
      requestFilter = {
        ...filter,
        tags: filter.tags.map((selectItem: SelectItem<number>) => selectItem.value)
      };
    }

    return requestFilter;
  }
}
