import { ChartOptions } from 'chart.js';

/* eslint-disable */
export const BarChartOptions: ChartOptions = {
  tooltips: {
    intersect: false,
    mode: 'index',
    position: 'nearest',
    filter: function (item) {
      return item.value !== '0';
    },
    // Disable the on-canvas tooltip
    enabled: false,

    custom: function (tooltipModel) {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip');

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        document.body.appendChild(tooltipEl);
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        (tooltipEl as any).style.opacity = 0;

        return;
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      const getBody = (bodyItem: any) => {
        return bodyItem.lines;
      };

      // Set Text
      if (tooltipModel.body) {
        const bodyLines = tooltipModel.body.map(getBody);

        let innerHtml = '';
        innerHtml += '<tbody>';

        bodyLines.forEach(function (body, i) {
          const colors = tooltipModel.labelColors[i];
          const color = 'background-color:' + (colors as any).backgroundColor;
          const square = '<span style="display: inline-block; height: 9px; width: 9px;' + color + '"></span>';
          innerHtml += '<tr><td>' + square + ' ' + body + '</td></tr>';
        });
        innerHtml += '</tbody>';

        const tableRoot = tooltipEl.querySelector('table');
        (tableRoot as any).innerHTML = innerHtml;

        if (bodyLines.length === 0) {
          tooltipEl.style.visibility = 'hidden';
        } else {
          tooltipEl.style.visibility = 'visible';
        }
      }

      // `this` will be the overall tooltip
      const position = (this as any)._chart.canvas.getBoundingClientRect();

      // Display, position, and set styles for font
      tooltipEl.style.opacity = '0.9';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
      tooltipEl.style.top = position.top + window.pageYOffset - 20 + 'px';
      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
      tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
      tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.backgroundColor = '#000';
      tooltipEl.style.color = '#fff';
      tooltipEl.style.borderRadius = '5px';
      tooltipEl.style.zIndex = '400';
    }
  },
  responsive: true,
  aspectRatio: 2.05,
  animation: {
    duration: 0
  },
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        display: true,
        stacked: true,
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 10
        }
      }
    ],
    yAxes: [
      {
        stacked: true,
        display: false
      }
    ]
  }
};
/* eslint-enable */
