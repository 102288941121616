import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[tmcScrollContainerItem]', // eslint-disable-line
  exportAs: 'tmcScrollContainerItem'
})
export class ScrollContainerItemDirective {
  @Input() tmcScrollContainerItem: any;

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}
