import { ChartOptions } from 'chart.js';

export const LineChartOptions: ChartOptions = {
  layout: {
    padding: {
      bottom: 1
    }
  },
  scales: {
    ticks: {},
    xAxes: [{ display: false }],
    yAxes: [
      {
        display: false,
        ticks: {
          beginAtZero: true
        }
      }
    ]
  },
  elements: {
    point: {
      radius: 0
    }
  },
  tooltips: {
    enabled: false
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 0
  }
};
