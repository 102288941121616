import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { DialogService } from '~app/shared/services';
import { ButtonComponent } from '~app/shared/components/button/button.component';
import { DIALOG_RESULT } from '~app/shared/components/dialog/dialog.constants';
import { DialogAction, DialogResult } from '~app/shared/components/dialog/models';
import { Subject } from 'rxjs';

@Component({
  selector: 'tmc-add-blocker-form',
  templateUrl: './add-blocker-form.component.html'
})
export class AddBlockerFromComponent implements OnInit, OnDestroy {
  public saveButton: ButtonComponent;
  private destroyed$ = new Subject<void>();

  constructor(
    public dialogService: DialogService,
    @Inject(DIALOG_RESULT) private dialogResult: DialogResult<AddBlockerFromComponent>
  ) {}

  ngOnInit(): void {
    this.initOkSubscription();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  initOkSubscription() {
    this.dialogResult
      .pipe(
        filter((result: DialogResult<AddBlockerFromComponent>) => result.action === DialogAction.Ok),
        tap(() => {
          this.dialogService.close();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }
}
