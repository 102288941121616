import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public storeInSession(key: string, data: any): void {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  public getFromSession<T>(key: string): T | null {
    const item = JSON.parse(sessionStorage.getItem(key) || 'null');

    return item as T;
  }

  public removeFromSession(key: string): void {
    sessionStorage.removeItem(key);
  }

  public clear(): void {
    sessionStorage.clear();
  }
}
