import { Component, Input, OnInit } from '@angular/core';

import { StatisticType } from '~app/shared/components/statistical-card/enums/statistic-type.enum';
import { InstrumentStat } from '~app/shared/models/instruments/instrument-stat.model';
import { BarChartOptions } from '~app/shared/utils/charts/bar-chart-options.config';
import { CountBarChartOptions } from '~app/shared/utils/charts/count-bar-chart-options.config';
import { ChartColorType } from '~app/shared/utils/charts/chart-color-type.config';

@Component({
  selector: 'tmc-instrument-statistics-charts',
  templateUrl: './instrument-statistics-charts.component.html',
  styleUrls: ['./instrument-statistics-charts.component.scss']
})
export class InstrumentStatisticsChartsComponent implements OnInit {
  @Input() stats: InstrumentStat | null;
  @Input() centerItems = false;
  @Input() centerItemsBreakpoint: string | null = null;

  public customClasses: string[] = [];
  public cardType = StatisticType;
  public chartColorType = ChartColorType;
  public barChartOptions = BarChartOptions;
  public countBarChartOptions = CountBarChartOptions;

  ngOnInit(): void {
    if (this.centerItems) {
      this.customClasses.push('justify-content-center');
      if (this.centerItemsBreakpoint) {
        this.customClasses.push(`justify-content-${this.centerItemsBreakpoint}-start`);
      }
    }
  }
}
