import { InjectionToken } from '@angular/core';
import { ButtonDesign } from '~app/shared/components/button/button-design.model';
import { DialogAction, DialogButtonConfig } from '~app/shared/components/dialog/models';

export const DIALOG_BUTTON_CONFIGS: { [key: string]: DialogButtonConfig } = {
  [DialogAction.Save]: {
    action: DialogAction.Save,
    label: 'Save',
    design: ButtonDesign.Primary
  },
  [DialogAction.Cancel]: {
    action: DialogAction.Cancel,
    label: 'Cancel',
    design: ButtonDesign.OutlinePrimary
  }
};

export const DIALOG_RESULT = new InjectionToken('tmc-dialog-service Dialog Result');
