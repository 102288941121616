import { Component } from '@angular/core';
import { DEFAULT_TAB } from '~app/shared/services';
import { AppComponentStore } from '~app/shared/store/app-component.store';

@Component({
  selector: 'tmc-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  constructor(public appComponentStore: AppComponentStore) {}

  get dashboardUrl() {
    return DEFAULT_TAB.url;
  }

  get dashboardTab() {
    return { navTab: DEFAULT_TAB };
  }
}
