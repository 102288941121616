import { IconOptions, latLng, tileLayer } from 'leaflet';

// Open Street Map Definition
export const LAYER_OSM = {
  id: 'openstreetmap',
  name: 'Open Street Map',
  enabled: false,
  layer: tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
    maxZoom: 18,
    attribution: 'Open Street Map'
  })
};

export const MAP_BASELAYERS = {
  ['Open Street Map']: LAYER_OSM.layer
};

export const MAP_OPTIONS = {
  minZoom: 2,
  maxZoom: 8,
  zoom: 3,
  center: latLng([20, 0]),
  attributionControl: false
};

export const MAP_ICON: IconOptions = {
  iconUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png'
};
