import { Pipe, PipeTransform, NgZone } from '@angular/core';
import { Instrument } from '~app/shared/models/instruments/instrument.model';
import { MAP_ICON } from './map.constants';
import { TabService } from '~app/shared/services/tab.service';
import { Icon, IconOptions, Layer, icon as L_icon, marker as L_marker } from 'leaflet';

@Pipe({ name: 'toMarkers' })
export class ToMarkersPipe implements PipeTransform {
  constructor(private ngZone: NgZone, private tabService: TabService) {}

  transform(instruments: Instrument[] | null): Layer[] {
    if (instruments === null) return [];

    return instruments
      .filter(instrument => Boolean(instrument.location))
      .map(instrument => {
        const { longitude, latitude } = instrument.location;
        const icon: Icon<IconOptions> = L_icon({
          ...MAP_ICON,
          className: instrument.serialNumber,
          iconSize: [25, 41],
          iconAnchor: [13, 41],
          popupAnchor: [0, -39]
        });
        const popup: string = this.generatePopup(instrument);
        const marker = L_marker([latitude, longitude], { icon })
          .bindPopup(popup, { closeButton: false })
          .on('click', () => this.onInstrumentClick(instrument))
          .on('mouseover', () => {
            marker.openPopup();
          })
          .on('mouseout', () => {
            marker.closePopup();
          });

        return marker;
      });
  }

  private generatePopup(instrumentInfo: any): string {
    const { serialNumber, size, type, location } = instrumentInfo;

    return `
      <div>Serial number: ${serialNumber}</div>
      <div>City: ${location.city}</div>
      <div>Size: ${size}</div>
      <div>Type: ${type}</div>
    `;
  }

  private onInstrumentClick(instrument: Instrument) {
    const { id, serialNumber } = instrument;
    const tab = {
      url: `/instruments/${id}`,
      id: serialNumber,
      label: serialNumber
    };

    this.ngZone.run(() => this.tabService.navigateByTab(tab));
  }
}
