import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isArray, isEqual } from 'lodash-es';

import { SelectItem } from '~app/shared/models/select-item.model';
import { CustomControl } from '~app/shared/services/base/custom-control';

@Component({
  selector: 'tmc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent extends CustomControl implements OnInit {
  @ViewChild('select', { static: true }) select: NgSelectComponent;

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() ngModelChange = new EventEmitter();

  @Input() ngModel: SelectItem[] | SelectItem | null = [];
  @Input() label = '';
  @Input() items: SelectItem[] = [];
  @Input() placeholder = '—';
  @Input() clearable = true;
  @Input() searchable = true;
  @Input() multiple = true;
  @Input() bindLabel = 'label';
  @Input() bindValue = '';
  @Input() loading = false;
  @Input() readonly = false;
  @Input() isOpen = false;

  constructor(@Self() @Optional() public ngControl: NgControl, cd: ChangeDetectorRef) {
    super(ngControl, cd);
  }

  ngOnInit() {
    if (this.hasPreselectedItems()) {
      this.changed.emit(this.ngModel);
    }
  }

  public writeValue(value: any | any[]): void {
    this.cd.detectChanges();
    this.ngModel = value;
    this.select.writeValue(value);
  }

  public registerOnChange(fn: any): void {
    this.select.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.select.registerOnTouched(fn);
  }

  public focus() {
    this.select.focus();
  }

  public compareWith(item: SelectItem, selectedItem: SelectItem) {
    return isEqual(item, selectedItem);
  }

  public change(value: SelectItem | SelectItem[]) {
    this.ngModel = value;
    this.ngModelChange.emit(value);
  }

  public close() {
    this.select.close();
  }

  private isNotEmptyArray(): boolean {
    return isArray(this.ngModel) && this.ngModel.length > 0;
  }

  private isObject(): boolean {
    return !isArray(this.ngModel);
  }

  private hasPreselectedItems() {
    return this.isNotEmptyArray() || this.isObject();
  }
}
