import { Component, Input } from '@angular/core';

@Component({
  selector: 'tmc-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent {
  @Input() isLoading: boolean | null;
  /**
   * Controls how the spinner is positioned.
   * `content` - Position the spinner relative to the content
   * `parent` - Position the spinner based on the first positioned ancestor
   * `viewport` - Position the spinner on the visible part of the screen
   */
  @Input() relativeTo: 'content' | 'parent' | 'viewport' = 'content';
  @Input() isTransparent = false;
  @Input() size: 'default' | 'small' | 'mini' = 'default';
  @Input() color: 'default' | 'primary' = 'default';
}
