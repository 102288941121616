<button
  [type]="type"
  class="d-flex align-items-center btn btn-{{ design }}"
  [disabled]="isDisabled"
  (click)="onButtonClick($event)"
>
  <div class="btn-content">
    <svg-icon *ngIf="iconName" [class.invisible]="isLoading" [name]="iconName" class="btn-icon"></svg-icon>
    <div *ngIf="label" [class.invisible]="isLoading" class="btn-label">{{ label | uppercase }}</div>
  </div>
</button>
<tmc-spinner class="btn-spinner" [isTransparent]="true" [isLoading]="isLoading" [size]="'small'" relativeTo="parent">
</tmc-spinner>
