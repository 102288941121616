<div class="menu">
  <div
    class="menu-button"
    *ngFor="let navLink of internalNavLinks"
    [routerLink]="[navLink.url]"
    [state]="getStateForNavigation(navLink.url)"
    [routerLinkActive]="['menu-button_active']"
    (click)="onMenuButtonClick()"
  >
    <div class="menu-button-content">
      <svg-icon name="{{ navLink.iconName }}" class="menu-button-icon"></svg-icon>
      <div class="menu-button-text">{{ navLink.text }}</div>
    </div>
  </div>

  <div class="menu-button" *ngFor="let actionLink of actionLinks" (click)="actionLink.action()">
    <div class="menu-button-content">
      <svg-icon name="{{ actionLink.iconName }}" class="menu-button-icon"></svg-icon>
      <div class="menu-button-text">{{ actionLink.text }}</div>
    </div>
  </div>

  <div class="menu-button" *ngFor="let navLink of externalNavLinks" (click)="onMenuButtonClick()">
    <a [href]="navLink.url" target="_blank" rel="noreferrer noopener">
      <div class="menu-button-content">
        <img [src]="navLink.iconSource" alt="introspect" class="menu-button-icon" />
        <div class="menu-button-text">{{ navLink.text }}</div>
      </div>
    </a>
  </div>

  <div class="version">
    <p>Version:</p>
    <p>{{ appVersion }}</p>
  </div>
</div>
