export const environment = {
  production: false,
  gaTrackingId: 'G-3EKN149MXN',
  api: {
    protocol: 'http',
    host: 'localhost:4500',
    path: 'mission-control/api',
    hubUrl: 'https://tecan-missioncontrol-dev.azurewebsites.net/hub/instruments',
    httpInterceptor: {
      allowedList: ['http://localhost:4500/mission-control/*']
    }
  },
  auth: {
    domain: 'tecan-poc-iot-01.eu.auth0.com',
    clientId: 'f4kYTmKFCwWKoHQuGCeQW0PcG1cbgvbP',
    audience: 'missioncontrolapi',
    redirectUri: 'http://localhost:4500',
    skipRedirectCallback: window.location.href.includes('error') && window.location.href.includes('error_description')
  }
};
