import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { featureGroup, Layer, Map } from 'leaflet';

import 'leaflet.markercluster';
import { MAP_OPTIONS, MAP_BASELAYERS } from './map.constants';

@Component({
  selector: 'tmc-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnChanges, AfterViewInit {
  @Input() markers: Layer[] = [];
  public readonly baseLayers = MAP_BASELAYERS;
  public readonly options = MAP_OPTIONS;

  public map: Map;

  public ngOnChanges(changes: SimpleChanges) {
    const markers = changes.markers?.currentValue;
    const length = markers?.length;

    if (length === 1) {
      const center = markers[0].getLatLng();
      this.options.center = center;
      this.map?.panTo(center);
    } else if (length > 0) {
      const group = featureGroup(markers);
      this.map?.fitBounds(group.getBounds());
    }
  }

  ngAfterViewInit() {
    // This fixes some problems with map's layers, when there is no size set to the map
    setTimeout(() => this.map?.invalidateSize());
  }

  public onMapReady(map: Map) {
    this.map = map;
  }
}
