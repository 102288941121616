import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { CoreComponent } from '~app/core/core.component';
import { ROUTES } from '~app/core/constants/routes';
import { SignalrSubscriptionGuard } from '~app/shared/guards/signalr-subscription.guard';
import { ErrorComponent } from './error/error.component';
import { ErrorGuard } from './shared/guards/error.guard';

const routes: Routes = [
  {
    path: ROUTES.ERROR.path,
    component: ErrorComponent
  },
  {
    path: '',
    component: CoreComponent,
    canActivate: [ErrorGuard],
    canActivateChild: [AuthGuard],
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [SignalrSubscriptionGuard],
        canDeactivate: [SignalrSubscriptionGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: ROUTES.INSTRUMENTS.path,
        canActivate: [SignalrSubscriptionGuard],
        canDeactivate: [SignalrSubscriptionGuard],
        loadChildren: () => import('./instruments/instruments.module').then(m => m.InstrumentsModule)
      },
      {
        path: ROUTES.TAGS.path,
        loadChildren: () => import('./tags/tags.module').then(m => m.TagsModule)
      },
      {
        path: '**',
        redirectTo: 'dashboard'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
