import { Component, Input } from '@angular/core';
import { InstrumentStatus } from '~app/shared/enums/instrument-status.enum';

import { InstrumentHelperService } from '~app/shared/services/instrument-helper.service';

@Component({
  selector: 'tmc-execution-status',
  templateUrl: './execution-status.component.html',
  styleUrls: ['./execution-status.component.scss']
})
export class ExecutionStatusComponent {
  @Input() statusType: keyof typeof InstrumentStatus;

  constructor(public instrumentHelper: InstrumentHelperService) {}
}
