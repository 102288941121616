import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { CoreComponent } from '~app/core/core.component';
import { HeaderComponent } from '~app/core/components/header/header.component';
import { SharedModule } from '~app/shared/shared.module';
import { NavTabsetComponent } from '~app/core/components/nav-tabset/nav-tabset.component';
import { ScrollContainerComponent } from '~app/core/components/scroll-container/scroll-container.component';
import { ScrollContainerItemDirective } from '~app/core/components/scroll-container/scroll-container-item.directive';
import { SideMenuComponent } from './components/side-menu/side-menu.component';

@NgModule({
  declarations: [
    CoreComponent,
    HeaderComponent,
    NavTabsetComponent,
    ScrollContainerComponent,
    ScrollContainerItemDirective,
    SideMenuComponent
  ],
  imports: [CommonModule, RouterModule, SharedModule]
})
export class CoreModule {}
