<div
  class="spinner"
  [class.relative-to-content]="relativeTo === 'content'"
  [class.relative-to-viewport]="relativeTo === 'viewport'"
  [class.spinner-small]="size === 'small'"
  [class.spinner-mini]="size === 'mini'"
  [class.spinner-primary]="color === 'primary'"
  [class.spinner-transparent]="isTransparent"
>
  <div class="spinner-overlay" [class.fade-in]="isLoading">
    <ng-container *ngIf="isLoading">
      <div class="spinner-ball"></div>
      <div class="spinner-ball"></div>
      <div class="spinner-ball"></div>
    </ng-container>
  </div>
  <ng-content></ng-content>
</div>
